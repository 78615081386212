a {
  text-decoration: none;
}

.homePageContainer {
}

.header {
  position: sticky;
  z-index: 1;
  top: 0;
  display: flex;
  background-color: var(--secondaryColorOrange);
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding-right: 30px;
  padding-left: 30px;
}

.homepageHeader {
  position: sticky;
  top: 0;
  display: flex;
  background-color: var(--primaryBackgroundColor);
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  padding-right: 30px;
  padding-left: 30px;
}

.headerRight {
  display: flex;
  align-items: center;
}

.headerPrices {
  text-decoration: none;
  color: var(--secondaryColorOrange);
  margin-right: 30px;
}

.headerPricesWhite {
  text-decoration: none;
  color: white;
  margin-right: 30px;
}

.businessPageLoginButton {
  background-color: var(--secondaryColorOrange);
  border: 1px solid var(--secondaryColorOrange);
  color: white;
  border-radius: 15px;
  padding-right: 8px;
  padding-left: 8px;
  font-size: 15px;
  cursor: pointer;
}

.businessPageLoginText {
  color: white;
  font-style: italic;
}

.businessPageLoginButton:hover {
  background-color: var(--primaryBackgroundColor);
  color: var(--secondaryColorOrange);
}

.homePageMainContainer {
  background-image: url("../assets/images/e-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 30%;
  padding-left: 30%;
  text-align: center;
}

.containerInfo p {
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
}

.textHighlight {
  color: white;
  border-radius: 20px;
  padding-right: 12px;
  padding-left: 12px;
  /* margin-right: 5px;
  margin-left: 5px; */
  padding-bottom: 2px;
  display: inline;
}

.textHighlight#restaurants {
  background-color: var(--purpleRestaurantColor);
  font-weight: normal;
}

.textHighlight#activities {
  background-color: var(--greenActivityColor);
  font-weight: normal;
}

.textHighlight#events {
  background-color: var(--blueEventsColor);
  font-weight: normal;
}

.textHighlight#downloadApp {
  margin-top: 35px;
  background-color: white;
  color: black;
  font-weight: bold;
}

.appstoreButtons {
  display: flex;
  margin-top: 15px;
}

.footerContainer {
  display: flex;
  align-items: center;
  height: 15vh;
  padding-left: 50px;
  background-color: var(--secondaryColorOrange);
  color: white;
}

.footerContact {
  margin-left: 150px;
}

.footerContact p {
  margin: 0;
  text-decoration: none;
  color: white;
}

.footerContact a {
  text-decoration: none;
}

.social {
  display: flex;
  flex-direction: row;
}

.pricesForm {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
}

.pricing-amount-block {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}

.pricing-amount-currency {
  position: relative;
  left: 0;
  top: -10px;
  right: 0;
  float: none;
  font-size: 14px;
}
.pricing-amount-figure {
  font-size: 35px;
  color: var(--secondaryColorOrange);
}

.loginPageMainContainer {
  background-image: url("../assets/images/e-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.loginpageLeft {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-left: 150px;
  padding-right: 50px;
  margin-bottom: 150px;
}

.loginpageLeft h1 {
  color: var(--secondaryColorOrange);
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.loginpageLeft h2 {
  font-weight: 600;
  margin-top: 0;
  font-size: 28px;
  padding-right: 140px;
}

.loginpageRight {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 50px;
  padding-right: 150px;
  margin-bottom: 100px;
}

.loginForm {
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
}

.loginForm h2 {
  color: var(--secondaryColorOrange);
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
}

.loginFormInputFields {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginFormInputFields span {
  font-weight: 700;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 25px;
}

.loginFormInputFields input {
  border-radius: 25px;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  height: 30px;
  border: none;
  padding-left: 10px;
}

.forgotPassword {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: black;
  text-decoration: underline;
}

.forgotPassword:hover {
  color: var(--secondaryColorOrange);
}

.loginIfUser {
  text-decoration: none;
  color: var(--secondaryColorOrange);
  margin-left: 3px;
}

.loginButton {
  margin-top: 35px;
  background-color: var(--secondaryColorOrange);
  color: white;
  border-radius: 15px;
  font-size: 16px;
  width: 30%;
  height: 25px;
  cursor: pointer;
  border: 0.5px solid var(--secondaryColorOrange);
}

.newAccountButton {
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: center;
  background-color: black;
  border: 0.5px solid black;
  color: white;
  border-radius: 15px;
  font-size: 16px;
  width: 30%;
  height: 25px;
}

.loginButton:hover {
  background-color: white;
  color: var(--secondaryColorOrange);
  border: 0.5px solid var(--secondaryColorOrange);
}

.newAccountButton:hover {
  background-color: white;
  border: 0.5px solid black;
  color: black;
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: center;
  border-radius: 15px;
  font-size: 16px;
  width: 30%;
  height: 25px;
}

.termsTextBoks {
  text-align: center;
  font-size: 14px;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 35px;
}

.pricesForm {
  width: 20vw;
  padding: 15px 0;
}

.pricesForm h2 {
  color: var(--secondaryColorOrange);
  text-align: center;
  font-weight: 600;
}

.pricing-amount-block {
  display: flex;
  align-items: flex-end;
}

.pricing-amount-figure {
  font-size: 55px;
  font-weight: 500;
  color: black;
}

.pricing-amount-currency {
  font-size: 18px;
  align-items: flex-start;
}

.stats-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.stats-page h2 {
  color: #343a40;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.profile-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-dropdown {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
}

.stats-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
}

.stats-table th,
.stats-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.stats-table th {
  background-color: var(--secondaryColorOrange);
  color: #ffffff;
  font-weight: bold;
}

.stats-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.stats-table tr:hover {
  background-color: #e2e6ea;
}

.BusinessPagesMainContainer {
  display: flex;
  min-height: 500px;
  padding-top: 25px;
  background-color: var(--primaryBackgroundColor);
}

.sidepanelcontainer {
  background-color: var(--primaryBackgroundColor);
  /* padding-top: 25px; */
  height: 550px;
  width: 18%;
}

.sidePanelMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  height: 500px;
  border-right: 3px solid var(--secondaryColorOrange);
}

.sidePanelMain h3 {
  font-weight: 600;
  font-size: 22px;
  margin-top: 25px;
}

.sidePanelMain p {
  margin-top: 25px;
  font-size: 16px;
}

.sidePanelMain a {
  color: var(--secondaryColorOrange);
}

.sidePanelMain a:hover {
  text-decoration: underline;
}

.BusinessPagesContent {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
}

.universalButton {
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: center;
  background-color: black;
  border: 0.5px solid black;
  color: white;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  height: 25px;
  margin-top: 0px;
  cursor: pointer;
}

.universalButton:hover {
  background-color: white;
  border: 0.5px solid black;
  color: black;
}

.universalButtonRed {
  text-align: center;
  background-color: var(--secondaryColorOrange);
  border: 0.5px solid var(--secondaryColorOrange);
  color: white;
  color: white;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  height: 25px;
  margin-top: 0px;
  cursor: pointer;
}

.universalButtonRed:hover {
  background-color: white;
  color: var(--secondaryColorOrange);
  border: 0.5px solid var(--secondaryColorOrange);
}

.box {
  background-color: white;
  border-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.businessOverView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.businessOverView .locationNameLabel {
  font-weight: bold;
}
.businessOverView label {
  width: 33%;
}

.businessOverView button {
  margin: 0;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.editLocationPage h1 {
  margin-bottom: 0;
}

.locationNameText {
  color: var(--secondaryColorOrange);
}

.aboutLocationEditting {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.aboutLocationEditting label {
  font-weight: 500;
}

.editMenuButtons {
  text-align: center;
  background-color: var(--secondaryColorOrange);
  border: 0.5px solid var(--secondaryColorOrange);
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 16px;
  width: 85px;
  height: 25px;
  margin-right: 2px;
  cursor: pointer;
}

.editAboutTop {
  display: flex;
  margin-top: 15px;
}

.editAboutPictureContainer {
  width: 20%;
}

.editAboutPictureContainer a {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 125px;
}

.editPageLink {
  color: gray;
  text-decoration: underline;
}

.editPicture {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primaryBackgroundColor);
  width: 125px;
  height: 125px;
  border-radius: 100%;
  box-shadow: 0 0 2px gray;
}

.editLocationPictures {
  justify-content: center;
  align-items: center;
  background-color: var(--primaryBackgroundColor);
  width: 172px;
  height: 120px;
  border-radius: 5%;
  box-shadow: 0 0 2px gray;
  cursor: pointer;
}

.editLocationPictures.drag-over {
  border-color: #000;
  background-color: #eee;
}

.delete-overlay {
  position: absolute;
  top: 60%;
  right: 25%;
  transform: translate(-100%, -100%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  cursor: pointer;
}

.editAboutNameTagsContainer {
  width: 100%;
}

.editPageLabel {
  font-weight: 600;
  margin-bottom: 5px;
}

input {
  border-radius: 25px;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  height: 30px;
  border: none;
  padding-left: 10px;
  width: 100%;
  box-shadow: 0 0 1px gray;
}

.inputCheckbox {
  border-radius: 25px;
  border: none;
  background-color: none;
  height: 16px;
  border: none;
  margin-right: 6px;
  width: 16px;
  box-shadow: none;
  margin-top: 0;
}

textarea {
  border-radius: 5px;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  height: 150px;
  border: none;
  padding-top: 5px;
  padding-left: 10px;
  width: 100%;
  box-shadow: 0 0 1px gray;
}

.timepicker {
  border-radius: 25px;
  width: 15%;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  /* height: 30px; */
  padding-left: 10px;
  box-shadow: 0 0 1px gray;
  margin-right: 10px;
}

.timepickerDiv {
  border-radius: 25px;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  /* height: 30px; */
  box-shadow: 0 0 1px gray;
}

.modalStyle {
  position: relative;
  top: 50%;
  left: 50%;
  /* box-shadow: 0 0 1px gray; */
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 1rem;
  border: 1px solid lightgray;
  padding: 20px 20px;
}

.editActiveModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.editActiveModalButtonContainer {
  margin-top: 20px;
}

.menuPageContainer {
  display: flex;
  flex-direction: column;
}

.menuPageItemInfoContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

select {
  border-radius: 25px;
  border: 0.5px solid var(--primaryBackgroundColor);
  background-color: var(--primaryBackgroundColor);
  height: 30px;
  border: none;
  padding-left: 10px;
  /* width: 100%; */
  box-shadow: 0 0 1px gray;
}

.menuListDropdown h3 {
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
  color: var(--secondaryColorOrange);
  cursor: pointer;
}

.menuListShown {
  background-color: var(--primaryBackgroundColor);
  padding: 10px;
  border-radius: 5px;
}

.menuListShownItem {
  margin-top: 5px;
  margin-bottom: 10px;
}

.subscriptionPageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
}

.subscriptionPageContainer .box {
  width: 30%;
}

.subscriptionPageContainer .box .boxInner {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.subscriptionPageContainer .box .boxInner2 {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.subscriptionPageContainer .box .boxInner2 .subscriptionInfoDate {
  font-weight: 600;
  font-size: 350%;
  color: var(--secondaryColorOrange);
}

.historyTable {
  display: flex;
  flex-direction: column;
}

.historyTableHeader {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr; /* Adjust these fractions based on your content needs */
  gap: 8px; /* This adds some space between your columns */
  color: gray;
}

.historyTableRow {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr; /* Adjust these fractions based on your content needs */
  gap: 8px; /* This adds some space between your columns */
  padding: 5px 0 5px 5px;
  border-radius: 10px;
  margin-top: 8px;
  background-color: var(--primaryBackgroundColor);
  color: gray;
}

.historyTableRow a {
  color: gray;
  text-decoration: underline;
}

.profileInfoBoxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
}

.profileInfoBoxes .box {
  width: 45%;
  padding: 20px;
  padding-bottom: 10px;
}

.profileInfoBoxes .box .innerBox {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.profileInfoBoxes .box .innerBox label {
  margin-top: 10px;
}
.profileInfoBoxes .box button {
  margin-top: 15px;
}

.profileUserAccess {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.profileUserAccess .box {
  padding: 20px;
  width: 100%;
}

.profileUserAccess .box a {
  color: gray;
  text-decoration: underline;
}

.eventHeader {
  background-color: var(--secondaryColorOrange);
  padding-left: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
}

.eventMain {
  display: grid;
  grid-template-columns: 35% 35% 1.5fr;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  /* padding-bottom: 0; */
}

.eventMain label {
  margin-top: 3px;
}

.eventMain div {
  display: flex;
  justify-content: flex-end;
}

.eventMain div a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 0;
  width: 30%;
}

.editPageMenuExcelContainer {
  margin-top: 15px;
  margin-left: 25px;
  margin-right: 25px;
  display: flex;
  justify-content: space-around;
}

.editPageExcelDownloadButton {
  width: 40%;
  cursor: pointer;
  background-color: var(--primaryBackgroundColor);
  border: 0.1px dashed #cccccc;

  color: black;
  border-radius: 15px;
}

.welcomePage {
  height: 300px;
}

.welcomePage h1 {
  display: flex;
}

.welcomePageContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}

.welcomePageSections {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  justify-content: flex-end;
}

.welcomePageSections label {
  font-weight: bold;
}

.welcomePageSections .box {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomePageSections img {
  height: 100px;
}

.faq_maincontainer {
  display: flex;
  margin-top: 25px;
  justify-content: space-evenly;
}

.faq_dropdownContainer {
  width: 100%;
}

.faq_dropdown {
  margin-bottom: 10px;
}

.dropdownItemContainer {
  background-color: var(--secondaryColorOrange);
  color: white;
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}

.faq_dropdown .box {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0;
  margin-left: 0;
}

.faq_right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  align-items: center;
  width: 100%;
}

.faq_right .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 50px;
  margin-bottom: 50px;
}

#hubspotForm {
  width: 100%;
}

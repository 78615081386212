.menu-container {
  max-width: 100%;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.menu-category {
  margin-bottom: 3rem; /* Increase the margin to separate categories */
  padding: 15px;
  background-color: #ffffff; /* Add a background color to make each category stand out */
  border-radius: 8px; /* Add rounded corners to the category */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for a raised effect */
  border: 1px solid #ddd; /* Add a border to further define the category */
}

.category-title {
  font-size: 1.8rem;
  color: #333;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 1.5rem; /* Increase bottom margin for more spacing */
}

.menu-item {
  margin-bottom: 2rem; /* Increase bottom margin between menu items */
}

.menu-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.menu-item-name {
  font-size: 1.5rem;
  color: #555;
}

.menu-item-price {
  font-size: 1.2rem;
  color: #999;
}

.menu-item-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.menu-item-allergens {
  font-size: 0.9rem;
  color: #999;
  font-style: italic;
}

@media (max-width: 768px) {
  .menu-container {
    padding: 15px;
  }

  .menu-category {
    padding: 10px;
    margin-bottom: 2rem;
  }

  .category-title {
    font-size: 1.5rem;
  }

  .menu-item-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-item-name {
    font-size: 1.3rem;
  }

  .menu-item-price {
    font-size: 1rem;
    margin-top: 5px;
  }

  .menu-item-description {
    font-size: 0.9rem;
  }

  .menu-item-allergens {
    font-size: 0.8rem;
  }
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primaryBackgroundColor: #f6f5f0;
  --whiteBackgroundColor: #ffffff;
  --secondaryColorOrange: #f9615b;
  --purpleRestaurantColor: #5d3160;
  --greenActivityColor: #52c78c;
  --blueEventsColor: #4550e2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
